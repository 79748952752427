import { Logo } from "@fonoa/ui-components/icons";
import classNames from "classnames";
import Head from "next/head";
import { ReactNode } from "react";

interface LoginLayoutProps {
  windowTitle: string;
  children: ReactNode;
  invitation?: boolean;
}

export default function LoginLayout({
  windowTitle = "Fonoa Dashboard",
  children,
  invitation,
}: LoginLayoutProps) {
  return (
    <>
      <Head>
        <title>{windowTitle}</title>
      </Head>
      <div className="relative h-full bg-dark-gradient">
        <div className="flex h-full items-center justify-center overflow-auto bg-dots">
          <div
            className={classNames(
              "relative m-4 max-w-login rounded bg-blueGray50 p-12 sm:w-4/5 md:w-3/5 lg:w-2/5",
              { "pb-8": invitation }
            )}
          >
            {children}
          </div>
        </div>
        <div className="absolute top-10 left-10">
          <Logo fillText="white" width={110} />
        </div>
      </div>
    </>
  );
}
