import { Button } from "@fonoa/ui-components/button";
import { FormLabel, RHFInputField } from "@fonoa/ui-components/forms";
import { CheckIcon, ClosedEyeIcon, EyeIcon, IconProps } from "@fonoa/ui-components/icons";
import { IconVariant } from "@fonoa/ui-components/input";
import { A } from "@fonoa/ui-components/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentType, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";

import LoginLayout from "@/components/Layouts/LoginLayout";
import { routes } from "@/lib/routes";

function getEmailInputIconProps({ email }: { email: string }): {
  iconVariant?: IconVariant;
  rightIcon?: ComponentType<IconProps>;
} {
  if (!email) return {};

  return { iconVariant: "SUCCESS", rightIcon: CheckIcon };
}

function getPasswordInputIconProps({
  password,
  passwordType,
  togglePasswordType,
}: {
  password: string;
  passwordType: "text" | "password";
  togglePasswordType: () => void;
}): {
  onRightIconClick?: () => void;
  rightIcon?: ComponentType<IconProps>;
} {
  if (!password) return {};

  switch (passwordType) {
    case "text":
      return { onRightIconClick: togglePasswordType, rightIcon: EyeIcon };
    case "password":
      return { onRightIconClick: togglePasswordType, rightIcon: ClosedEyeIcon };
    default:
      return {};
  }
}

function useSchema() {
  const intl = useIntl();
  return z.object({
    email: z
      .string()
      .min(
        1,
        intl.formatMessage({ defaultMessage: "Please enter your email address", id: "/vJwLB" })
      )
      .email(
        intl.formatMessage({ defaultMessage: "Please enter a valid email address", id: "oLpv29" })
      ),
    password: z
      .string()
      .min(1, intl.formatMessage({ defaultMessage: "Please enter your password", id: "iiYrag" }))
      .min(
        6,
        intl.formatMessage({ defaultMessage: "Must be 6 or more characters long", id: "Y1n+H3" })
      ),
  });
}

interface LoginProps {
  onLogin: (props: { email: string; password: string }) => Promise<void>;
  isLoginInProgress: boolean;
}

export function Login({ onLogin, isLoginInProgress }: LoginProps) {
  const intl = useIntl();
  const schema = useSchema();
  const [passwordType, setPasswordType] = useState<"text" | "password">("password");

  const methods = useForm<{
    email: string;
    password: string;
  }>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await onLogin(data);
    } catch (error) {
      setError(
        "password",
        {
          message: intl.formatMessage({
            defaultMessage: "Please check your password",
            id: "mTuWIU",
          }),
        },
        {
          shouldFocus: true,
        }
      );
    }
  });

  const email = watch("email");
  const password = watch("password");

  const togglePasswordType = () => setPasswordType(passwordType === "text" ? "password" : "text");

  const isButtonDisabled = [
    isLoginInProgress,
    !email,
    !password,
    password && password.length < 6,
    Boolean(errors.email),
    Boolean(errors.password),
  ].some(Boolean);

  return (
    <FormProvider {...methods}>
      <LoginLayout windowTitle="Login | Fonoa Dashboard">
        <div className="text-center text-3xl font-medium">
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        </div>
        <form onSubmit={onSubmit}>
          <div className="mt-2 pt-6">
            <FormLabel spacing={{ mb: 1.5 }} size="xs" id="email-label">
              <FormattedMessage defaultMessage="Email" id="sy+pv5" />
            </FormLabel>
            <RHFInputField
              name="email"
              inputProps={{
                type: "email",
                size: "REGULAR",
                fluid: true,
                renderErrorContainer: true,
                disabled: isLoginInProgress,
                ...getEmailInputIconProps({ email }),
                placeholder: intl.formatMessage({
                  defaultMessage: "Email",
                  id: "sy+pv5",
                }),
                "aria-labelledby": "email-label",
              }}
            />
          </div>
          <div className="mt-2 h-16">
            <FormLabel spacing={{ mb: 1.5 }} size="xs" id="password-label">
              <FormattedMessage defaultMessage="Password" id="5sg7KC" />
            </FormLabel>
            <div className="relative">
              <RHFInputField
                name="password"
                inputProps={{
                  type: passwordType,
                  size: "REGULAR",
                  fluid: true,
                  iconVariant: "GRAY",
                  className: passwordType === "password" ? "text-blueGray600" : "",
                  renderErrorContainer: true,
                  disabled: isLoginInProgress,
                  minLength: 6,
                  ...getPasswordInputIconProps({ password, passwordType, togglePasswordType }),
                  placeholder: "******",
                  "aria-labelledby": "password-label",
                }}
              />
              <div className="absolute top-9 right-0 mt-1 whitespace-nowrap">
                <A
                  href={routes.forgottenPassword}
                  containerProps={{ className: "text-sm absolute mt-0.5 right-0" }}
                  className="text-xs"
                >
                  <FormattedMessage defaultMessage="Forgotten password?" id="7D80S2" />
                </A>
              </div>
            </div>
          </div>
          <div className="mt-13.5">
            <Button
              type="submit"
              fluid
              loading={isLoginInProgress}
              disabled={isButtonDisabled}
              overrideDisabledStyle
            >
              <FormattedMessage defaultMessage="Continue" id="acrOoz" />
            </Button>
          </div>
        </form>
      </LoginLayout>
    </FormProvider>
  );
}

export const LoginConnected = () => {
  const onLogin = () => new Promise<void>((resolve) => resolve());

  return <Login onLogin={onLogin} isLoginInProgress={false} />;
};
